import { GameEvent } from "@/ts/business/game/event/GameEvent";


export class NavigationGameEvent extends GameEvent {
    private readonly backwards: boolean;
    private readonly jumpToKeyMoves: boolean;

    constructor(isBackwards: boolean, jumpToKeyMoves: boolean) {
        super("navigation");
        this.backwards = isBackwards;
        this.jumpToKeyMoves = jumpToKeyMoves;
    }

    isBackwards(): boolean {
        return this.backwards;
    }

    isForwards(): boolean {
        return !this.backwards;
    }

    shouldJumpToKeyMoves(): boolean {
        return this.jumpToKeyMoves;
    }
}
