import styles from "./BoardUI.module.scss";
import React, { useEffect, useMemo, useRef } from "react";
import { CanvasRenderer, RenderedCanvas } from "@/app_components/generic/render/RenderedCanvas";
import { BoardController } from "@/ts/business/game/controller/board/BoardController";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { useBoardController, useBoardDirective } from "@/app_components/game/board/BoardUIContext";
import { BoardAsset } from "@/app_components/game/board/BoardAsset";
import { drawPiece, rgb } from "@/app_components/game/render/render";
import { MouseListener, MouseState, useMouseListener } from "@/app_components/generic/MouseListener";
import { Tile } from "@/ts/royalur/model/Tile";
import { Piece } from "@/ts/royalur/model/Piece";
import { Move } from "@/ts/royalur/model/Move";
import { Seconds } from "@/ts/util/units";
import { getTimeSeconds, LONG_TIME_AGO } from "@/ts/util/utils";
import { Vec2 } from "@/ts/util/Vec2";
import { PathPair } from "@/ts/royalur/model/path/PathPair";
import {
    clamp,
    easeInOutCubic,
    easeInOutSine,
    min,
} from "@/ts/util/numbers";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { Fade } from "@/ts/business/fades";
import { MemoCache } from "@/ts/business/MemoCache";
import { Key, registerKeyListener } from "@/app_components/generic/KeyListener";
import { useGameTheme } from "@/app_components/game/theme/GameThemeContext";
import { GameTheme } from "@/app_components/game/theme/GameTheme";
import Image from "next/image";
import { APIGamePreferences } from "@/ts/business/api/api_schema";
import { useGamePreferences } from "@/app/(api)/api/getGamePreferences/useGamePreferences";
import { useOptionalUser } from "@/app_components/user/UserContext";
import { StandardBoardShape } from "@/ts/royalur/model/shape/StandardBoardShape";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { useRune } from "@/app_util/useRune";
import { useGameController } from "@/app_components/game/GameUIContext";
import { AssetLoader } from "@/app_components/assets/AssetLoader";
import { useAssetLoader } from "@/app_components/assets/AssetContext";
import { SharedRendererState } from "@/app_components/game/SharedRendererState";
import { cn } from "@/ts/util/cn";
import { NavigationGameEvent } from "@/ts/business/game/event/NavigationGameEvent";
import { MoveBoardDirective } from "@/ts/business/game/controller/board/MoveBoardDirective";
import { PlayBoardDirective } from "@/ts/business/game/controller/board/PlayBoardDirective";
import { QuickMovesBoardDirective } from "@/ts/business/game/controller/board/QuickMovesBoardDirective";
import { AnalysisBoardDirective } from "@/ts/business/game/controller/board/AnalysisBoardDirective";
import { MoveCategoryIcon } from "@/app_components/icon/analysis/MoveCategoryIcon";
import { FadingDiv } from "@/app_components/layout/FadingDiv";
import { useBounds } from "@/app_components/accessibility/useBounds";


class BoardRenderer extends CanvasRenderer {
    public static readonly PIECE_FADE_DURATION: Seconds = 0.15;

    /**
     * Moves take longer if they move over more tiles.
     */
    static readonly MOVE_DURATIONS: Seconds[] = [0, 0.35, 0.45, 0.55, 0.65];
    static readonly SCORE_DURATION: Seconds = 0.15;
    static readonly CAPTURE_DURATION: Seconds = 0.1;
    static readonly MOVE_ROSETTE_FLASH_DURATION: Seconds = 0.3;
    static readonly DRAG_DRAW_LINE_THRESHOLD = 0.25;
    static readonly PIECE_HOVERED_WIDTH = 1.1;

    static readonly AUDIO_DELAY = -0.1;

    private readonly controller: BoardController;
    private mouseListener: MouseListener;
    private readonly sharedRendererState: SharedRendererState;
    private readonly assetLoader: AssetLoader;
    private readonly theme: GameTheme;
    private readonly boardAsset: BoardAsset;

    // Linked dynamically to avoid re-creating the renderer when they change.
    private players: GamePlayers | null = null;
    private preferences: APIGamePreferences | null = null;

    private isPathActive: boolean = false;
    private readonly pathAlphaFade = new Fade(0.2);

    private readonly lightStartFade = new Fade(BoardRenderer.PIECE_FADE_DURATION);
    private readonly darkStartFade = new Fade(BoardRenderer.PIECE_FADE_DURATION);

    private readonly curveCache = new MemoCache<Vec2[]>("curveCache", 3);
    private readonly renderCurveCache = new MemoCache<Vec2[]>("renderCurveCache", 3);

    private lastDirective: BoardDirective | null = null;
    private selectedTile: Tile | null = null;

    private quickMovesIndex: number = 0;
    private moveStartTime: Seconds = LONG_TIME_AGO;
    private moveDuration: Seconds = 0;
    private animDuration: Seconds = 0;
    private moveGrantsExtraRoll: boolean = false;
    private moveAndAnimDuration: Seconds = 0;
    private runFinishedRender: boolean = false;
    private playedPlaceSound: boolean = false;
    private playedVictorySound: boolean = false;

    constructor(
        theme: GameTheme,
        controller: BoardController,
        mouseListener: MouseListener,
        sharedRendererState: SharedRendererState,
        assetLoader: AssetLoader,
    ) {
        super();
        this.controller = controller;
        this.sharedRendererState = sharedRendererState;
        this.assetLoader = assetLoader;
        this.mouseListener = mouseListener;
        this.theme = theme;
        this.boardAsset = this.theme.createBoardAsset();
    }

    setPlayers(players: GamePlayers) {
        this.players = players;
    }

    getPlayers(): GamePlayers {
        if (this.players === null)
            throw new Error("players is not yet available");
        return this.players;
    }

    setPreferences(preferences: APIGamePreferences) {
        this.preferences = preferences;
    }

    getPreferences(): APIGamePreferences {
        if (this.preferences === null)
            throw new Error("preferences is not yet available");
        return this.preferences;
    }

    override attachListeners(): () => void {
        const mouseDownCallback = (state: MouseState) => this.onMouseDown(state);
        const mouseMoveCallback = (state: MouseState) => this.onMouseMove(state);
        const mouseReleaseCallback = (state: MouseState) => this.onMouseRelease(state);
        const keyPressCallback = (event: KeyboardEvent) => this.onKeyPress(event);

        this.mouseListener.addMouseDownListener(mouseDownCallback);
        this.mouseListener.addMouseMoveListener(mouseMoveCallback);
        this.mouseListener.addMouseReleaseListener(mouseReleaseCallback);
        const unregisterKeyListener = registerKeyListener(keyPressCallback);

        return () => {
            this.mouseListener.removeMouseDownListener(mouseDownCallback);
            this.mouseListener.removeMouseMoveListener(mouseMoveCallback);
            this.mouseListener.removeMouseReleaseListener(mouseReleaseCallback);
            unregisterKeyListener();
        };
    }

    getBoardAsset(): BoardAsset {
        return this.boardAsset;
    }

    setPathActive(isPathActive: boolean) {
        if (isPathActive && !this.isPathActive) {
            this.unselectTile();
        }
        this.isPathActive = isPathActive;
        this.pathAlphaFade.fadeInOrOut(isPathActive);
    }

    selectTile(tile: Tile, skipPickupSound: boolean = false) {
        this.selectedTile = tile;
        if (!skipPickupSound) {
            this.playPickupSound();
        }
    }

    unselectTile() {
        this.selectedTile = null;
    }

    private processDirectives(): {
        directive: BoardDirective;
        moveDirective: MoveBoardDirective | null;
        playDirective: PlayBoardDirective | null;
        analysisDirective: AnalysisBoardDirective | null;
    } {
        let directive = this.controller.getActiveDirective();
        if (directive.getDirectiveID() !== this.lastDirective?.getDirectiveID()) {
            this.lastDirective = directive;
            this.onNewDirective(directive);
        }

        const time = getTimeSeconds();
        const animTime = time - this.moveStartTime;

        const originalDirective = directive;
        if (animTime >= this.moveAndAnimDuration) {
            if (this.runFinishedRender) {
                if (directive instanceof MoveBoardDirective) {
                    this.controller.popActiveDirective(directive);
                    directive = this.controller.getActiveDirective();

                } else if (directive instanceof QuickMovesBoardDirective) {
                    this.quickMovesIndex += 1;
                    if (this.quickMovesIndex >= directive.moves.length) {
                        this.controller.popActiveDirective(directive);
                        directive = this.controller.getActiveDirective();
                    } else {
                        const moveDirective = directive.moves[this.quickMovesIndex];
                        this.processDirectiveStart(moveDirective, 0.75, 0.5);
                    }
                }
            } else {
                this.runFinishedRender = true;
            }
        }

        if (directive !== originalDirective)
            return this.processDirectives();

        const directives = this.getDirective();
        this.sharedRendererState.currentMoveIndex.set(directives.directive.moveIndex);
        this.sharedRendererState.isAnimatingQuickMoves.set(directive instanceof QuickMovesBoardDirective);
        return directives;
    }

    private getDirective(): {
        directive: BoardDirective;
        moveDirective: MoveBoardDirective | null;
        playDirective: PlayBoardDirective | null;
        analysisDirective: AnalysisBoardDirective | null;
    } {
        const rawDirective = this.controller.getActiveDirective();

        let directive: BoardDirective;
        if (rawDirective instanceof QuickMovesBoardDirective) {
            directive = rawDirective.moves[this.quickMovesIndex];
        } else {
            directive = rawDirective;
        }

        return {
            directive,
            moveDirective: (directive instanceof MoveBoardDirective ? directive : null),
            playDirective: (directive instanceof PlayBoardDirective ? directive : null),
            analysisDirective: (directive instanceof AnalysisBoardDirective ? directive : null),
        };
    }

    private onNewDirective(directive: BoardDirective) {
        this.mouseListener.clearHover();

        if (directive instanceof QuickMovesBoardDirective) {
            this.quickMovesIndex = 0;
            this.processDirectiveStart(directive.moves[0], 0.75, 0.5);

        } else {
            this.processDirectiveStart(directive, 1.0, 1.0);
        }
    }

    private processDirectiveStart(directive: BoardDirective, speed: number, extraRollFlashSpeed: number) {
        this.runFinishedRender = false;

        if (directive instanceof PlayBoardDirective || directive instanceof AnalysisBoardDirective) {
            if (directive instanceof PlayBoardDirective) {
                const availableMoves = directive.availableMoves;
                const paths = directive.rules.getPaths();

                if (availableMoves && availableMoves.length === 1
                    && this.getPreferences().autoSelectSingleMoveOption) {

                    const forcedMove = availableMoves[0];
                    this.selectTile(forcedMove.getSource(paths), true);
                } else {
                    this.unselectTile();
                }

                if (directive.isGameWon) {
                    this.playVictorySound();
                }

            } else {
                this.unselectTile();
            }

            this.moveStartTime = LONG_TIME_AGO;
            this.moveDuration = LONG_TIME_AGO;
            this.moveGrantsExtraRoll = false;
            this.moveAndAnimDuration = LONG_TIME_AGO;

        } else if (directive instanceof MoveBoardDirective) {

            const move = directive.move;
            const paths = directive.rules.getPaths();
            this.moveStartTime = getTimeSeconds();

            const length = move.getLength(paths);
            const winningMoveSlowdown = (directive.moveWinsGame ? 1.5 : 1);
            this.moveDuration = (
                speed * BoardRenderer.MOVE_DURATIONS[length] * winningMoveSlowdown
            );

            if (move.isScore()) {
                this.animDuration = speed * BoardRenderer.SCORE_DURATION * winningMoveSlowdown;
            } else if (move.isCapture()) {
                this.animDuration = speed * BoardRenderer.CAPTURE_DURATION;
            } else {
                this.animDuration = 0;
            }

            const boardShape = directive.rules.getBoardShape();

            let moveGrantsExtraRoll = move.isLandingOnRosette(boardShape);
            if (directive.rules.doCapturesGrantExtraRolls() && move.isCapture()) {
                moveGrantsExtraRoll = true;
            }
            this.moveGrantsExtraRoll = moveGrantsExtraRoll;

            let moveAnimDuration = this.moveDuration + this.animDuration;
            if (this.moveGrantsExtraRoll) {
                moveAnimDuration += extraRollFlashSpeed * BoardRenderer.MOVE_ROSETTE_FLASH_DURATION;
            }
            this.moveAndAnimDuration = moveAnimDuration;
            this.playedPlaceSound = false;
            this.playedVictorySound = false;
        } else {
            throw new Error("Unknown board directive type " + directive);
        }
    }

    onMouseDown(state: MouseState): boolean {
        if (state.location === null)
            throw new Error("onMouseDown with no mouse location");
        if (!this.boardAsset.hasBeenRendered())
            return false;

        const { playDirective } = this.getDirective();
        if (playDirective === null)
            return false;

        // Check whether the user clicked on a tile.
        const tile = this.boardAsset.getTileAt(state.location, this.getPlayers().getLeftPlayer());
        if (tile === null) {
            this.unselectTile();
            return false;
        }

        // Check if there is a possible move from the clicked piece.
        if (playDirective.availableMoves !== null) {
            const paths = playDirective.rules.getPaths();
            const moveBySource = Move.findMoveBySourceTile(
                playDirective.availableMoves, tile, paths,
            );
            if (moveBySource !== null) {
                if (!state.isTouch || this.selectedTile?.equals(tile)) {
                    this.controller.handleMove(moveBySource, false);
                } else {
                    this.selectTile(tile);
                }
                return true;
            }

            if (this.selectedTile !== null) {
                const selectedMove = Move.findMoveBySourceTile(
                    playDirective.availableMoves, this.selectedTile, paths,
                );
                if (selectedMove !== null && tile.equals(selectedMove.getDest(paths))) {
                    this.controller.handleMove(selectedMove, false);
                }
            }
        }

        // Clicked on a tile/piece not associated with an available move.
        this.unselectTile();
        return false;
    }

    onMouseMove(state: MouseState): boolean {
        if (state.location === null)
            return false;

        return state.mouseDown && this.selectedTile !== null;
    }

    onMouseRelease(state: MouseState): boolean {
        if (this.selectedTile === null || state.location === null)
            return false;
        if (!this.boardAsset.hasBeenRendered())
            return false;

        const { playDirective } = this.getDirective();
        if (playDirective === null)
            return false;

        const availableMoves = playDirective.availableMoves;
        if (availableMoves === null)
            return false;

        const paths = playDirective.rules.getPaths();
        const move = Move.findMoveBySourceTile(availableMoves, this.selectedTile, paths);
        if (move === null)
            return false;

        const destTile = move.getDest(paths);
        const hoveredTile = this.boardAsset.getTileAt(state.location, this.getPlayers().getLeftPlayer());
        if (!hoveredTile?.equals(destTile))
            return false;

        this.playPlaceSound();
        this.controller.handleMove(move, true);
        return true;
    }

    onKeyPress(event: KeyboardEvent) {
        const keyIsLeft = Key.ARROW_LEFT.isKey(event.key);
        const keyIsUp = Key.ARROW_UP.isKey(event.key);
        const keyIsRight = Key.ARROW_RIGHT.isKey(event.key);
        const keyIsDown = Key.ARROW_DOWN.isKey(event.key);
        if (keyIsLeft || keyIsUp || keyIsRight || keyIsDown) {
            this.controller.handleNavigation(new NavigationGameEvent(
                keyIsLeft || keyIsUp, false,
            ));
            return;
        }

        const keyIsEnter = Key.ENTER.isKey(event.key) || Key.Q.isKey(event.key);
        const keyIsSpace = Key.SPACE.isKey(event.key);
        if (!keyIsEnter && !keyIsSpace)
            return;

        const { playDirective } = this.getDirective();
        if (playDirective === null)
            return;

        const availableMoves = playDirective.availableMoves;
        const paths = playDirective.rules.getPaths();
        if (availableMoves === null)
            return;

        event.stopPropagation();

        // Move the selected piece.
        if (this.selectedTile !== null && (keyIsEnter || availableMoves.length === 1)) {
            const move = Move.findMoveBySourceTile(
                availableMoves, this.selectedTile, paths,
            );
            if (move !== null) {
                this.controller.handleMove(move, false);
                return;
            }
        }

        // Select the available piece.
        if (availableMoves.length === 1 && this.selectedTile === null) {
            const move = availableMoves[0];
            this.selectTile(move.getSource(paths));
            return;
        }

        // Cycle through available moves to select.
        if (keyIsSpace && availableMoves.length > 1) {
            const sortedMoves = [...availableMoves];
            sortedMoves.sort(function (move1, move2) {
                if (move1.isScore() && move2.isScore())
                    return 0;
                if (move1.isScore())
                    return 1;
                if (move2.isScore())
                    return -1;
                return move1.getDestPiece().getPathIndex() - move2.getDestPiece().getPathIndex();
            });

            let selectedIndex = -1;
            for (let index = 0; index < sortedMoves.length; ++index) {
                const move = sortedMoves[index];
                if (move.getSource(paths).equals(this.selectedTile)) {
                    selectedIndex = index;
                    break;
                }
            }

            const targetIndex = (selectedIndex + 1) % sortedMoves.length;
            this.selectTile(sortedMoves[targetIndex].getSource(paths));
        }
    }

    playPickupSound() {
        this.theme.getPickupSound().play();
    }

    playPlaceSound() {
        this.theme.getPlaceSound().play();
    }

    playRosetteSound() {
        this.theme.getRosetteSound().play();
    }

    playCaptureSound() {
        this.theme.getCaptureSound().play();
    }

    playScoreSound() {
        this.theme.getScoreSound().play();
    }

    playVictorySound() {
        if (this.playedVictorySound)
            return;

        this.playedVictorySound = true;
        return this.theme.getVictorySound().play(true);
    }

    maybeStartMusic() {
        const activeTrack = this.assetLoader.getActiveMusicTrack().get().getOrNull();
        if (activeTrack)
            return;

        this.theme.findMusicTrackToPlay()?.tryPlay();
    }

    override render(
        ctx: CanvasRenderingContext2D,
        width: number,
        height: number,
    ): void {
        const {
            directive, moveDirective,
            playDirective, analysisDirective,
        } = this.processDirectives();

        const rules = directive.rules;
        const board = directive.getBoard();
        const move = moveDirective?.move ?? null;
        const activePlayer = directive.getActivePlayer();
        const paths = rules.getPaths();

        // Clear the board.
        this.clearCanvas(ctx, width, height);
        this.boardAsset.draw(ctx, 0, 0, width, height);

        // Determine whether the player can introduce a piece to the board.
        const availableMoves = playDirective?.availableMoves ?? [];
        const introductionMove = Move.findIntroductionMove(availableMoves);
        const hasIntroducingMove = (introductionMove !== null);
        const introductionTile = paths.getStart(activePlayer);

        // Determine the piece that is being hovered.
        const mouseState = this.mouseListener.getMouseState();
        let hoveredTile: Tile | null = null;
        let hoveredPiece: Piece | null = null;
        this.sharedRendererState.highlightIntroducedPiece = (
            !move && introductionTile.equals(this.selectedTile) ? activePlayer : null
        );
        if (mouseState.location !== null) {
            hoveredTile = this.boardAsset.getTileAt(mouseState.location, this.getPlayers().getLeftPlayer());
            if (hoveredTile !== null) {
                // Introduction pieces don't exist on the board.
                if (introductionTile.equals(hoveredTile) && hasIntroducingMove) {
                    this.sharedRendererState.highlightIntroducedPiece = (!move ? activePlayer : null);
                    hoveredPiece = new Piece(activePlayer, -1);
                } else {
                    hoveredPiece = board.get(hoveredTile);
                }
            }
        }

        // Find the move to preview.
        let previewMove: Move | null = null;
        let previewIsValidMove: boolean = false;
        let previewReactsToMouse: boolean = true;
        let isLightStartPieceHighlighted: boolean = false;
        let isDarkStartPieceHighlighted: boolean = false;

        if (analysisDirective?.previewMove) {
            previewMove = analysisDirective.previewMove;
            previewIsValidMove = true;
            hoveredTile = null;
            hoveredPiece = null;
            previewReactsToMouse = false;

            for (const moveHighlight of analysisDirective.moveHighlights) {
                if (moveHighlight.move.isIntroduction()) {
                    if (moveHighlight.move.getPlayer() === PlayerType.LIGHT) {
                        isLightStartPieceHighlighted = true;
                    } else {
                        isDarkStartPieceHighlighted = true;
                    }
                }
            }

        } else if (availableMoves.length > 0) {
            let previewMoveSourceTile: Tile | null = null;
            let previewMoveSourcePiece: Piece | null = null;

            // Determine the source tile for the preview move.
            if (this.selectedTile !== null) {
                previewMoveSourceTile = this.selectedTile;

                // Introduction pieces don't exist on the board.
                if (hasIntroducingMove && introductionTile.equals(this.selectedTile)) {
                    previewMoveSourcePiece = new Piece(activePlayer, -1);
                } else {
                    previewMoveSourcePiece = board.get(this.selectedTile);
                }
            } else if (hoveredPiece?.getOwner() === activePlayer) {
                previewMoveSourceTile = hoveredTile;
                previewMoveSourcePiece = hoveredPiece;
            }

            // Determine the move from the source tile.
            if (previewMoveSourceTile !== null && previewMoveSourcePiece !== null) {
                previewMove = Move.findMoveBySourceTile(availableMoves, previewMoveSourceTile, paths);

                if (previewMove !== null) {
                    previewIsValidMove = true;
                } else {
                    if (!playDirective?.roll)
                        throw new Error("Unexpectedly missing a dice roll when there are available moves");

                    // No move available from the tile.
                    // Create an invalid move to preview instead.
                    const path = paths.getWithStartEnd(activePlayer);
                    const sourceIndex = previewMoveSourcePiece.getPathIndex() + 1;
                    const destIndex = min(path.length - 1, sourceIndex + playDirective.roll.value());
                    const destTile = path[destIndex];
                    const destPiece = new Piece(activePlayer, destIndex - 1);
                    const capturedPiece = board.get(destTile);
                    previewMove = new Move(
                        activePlayer, previewMoveSourceTile, previewMoveSourcePiece,
                        destTile, destPiece, capturedPiece,
                    );
                    previewIsValidMove = false;
                }
            }
        }

        // Determine the pieces to ignore drawing.
        const ignoreDrawTiles: Tile[] = [];
        if (move !== null) {
            ignoreDrawTiles.push(move.getSource(paths));
            ignoreDrawTiles.push(move.getDest(paths));
        }
        if (previewMove !== null) {
            ignoreDrawTiles.push(previewMove.getSource(paths));
            if (this.getPreferences().showMoveGuidelines) {
                ignoreDrawTiles.push(previewMove.getDest(paths));
            }
        }

        // Draw the pieces on the board that aren't involved in a move.
        const shape = board.getShape();
        const pieceWidth = this.boardAsset.getTileWidth();

        ctx.save();
        try {
            const lightStartTile = paths.getStart(PlayerType.LIGHT);
            const darkStartTile = paths.getStart(PlayerType.DARK);

            for (let y = 1; y <= shape.getHeight(); ++y) {
                for (let x = 1; x <= shape.getWidth(); ++x) {
                    const tile = new Tile(x, y);
                    if (Tile.listContains(ignoreDrawTiles, tile))
                        continue;

                    const isLightStartPiece = Tile.areEqual(tile, lightStartTile);
                    const isDarkStartPiece = Tile.areEqual(tile, darkStartTile);
                    const isStartPiece = (isLightStartPiece || isDarkStartPiece);
                    const startPlayerType = (isLightStartPiece ? PlayerType.LIGHT : PlayerType.DARK);
                    const startFade = (
                        isLightStartPiece ? this.lightStartFade : this.darkStartFade
                    );
                    const startFadeValue = startFade.get();

                    let piece;
                    let bigStartPiece = false;
                    if (board.contains(tile)) {
                        piece = board.get(tile);

                    } else if (isStartPiece) {
                        let showStartPiece = (
                            hasIntroducingMove && startPlayerType === activePlayer
                        );
                        if ((isLightStartPieceHighlighted && startPlayerType === PlayerType.LIGHT)
                            || (isDarkStartPieceHighlighted && startPlayerType === PlayerType.DARK)) {

                            bigStartPiece = true;
                            showStartPiece = true;
                        }

                        startFade.fadeInOrOut(showStartPiece);
                        if (showStartPiece || startFadeValue > 0) {
                            piece = new Piece(startPlayerType, 0);
                        }
                    }
                    if (!piece)
                        continue;

                    let drawPieceWidth = pieceWidth;
                    let pieceSelected = false;

                    if (this.selectedTile?.equals(tile)) {
                        pieceSelected = true;
                    } else if (hoveredTile?.equals(tile) && piece.getOwner() === activePlayer) {
                        drawPieceWidth *= BoardRenderer.PIECE_HOVERED_WIDTH;
                    }
                    if (isStartPiece && !bigStartPiece) {
                        drawPieceWidth *= 0.95;
                    }

                    const owner = piece.getOwner();
                    const pieceImage = this.theme.getPieceImage(owner, pieceSelected);
                    const bounds = this.boardAsset.getTileBounds(tile, this.getPlayers().getLeftPlayer());

                    try {
                        if (isStartPiece) {
                            ctx.globalAlpha = startFade.get();
                        }
                        drawPiece(ctx, pieceImage, bounds.getCentre(), drawPieceWidth);
                    } finally {
                        if (isStartPiece) {
                            ctx.globalAlpha = 1.0;
                        }
                    }
                }
            }
        } finally {
            ctx.restore();
        }

        // Draw the moving piece.
        if (move !== null) {
            if (moveDirective === null)
                throw new Error("Unexpectedly missing moveDirective when move is available");

            if (move.isIntroduction()) {
                const startFade = (
                    move.getPlayer() === PlayerType.LIGHT
                        ? this.lightStartFade : this.darkStartFade
                );
                startFade.fadeOut(0);
            }
            ctx.save();
            try {
                this.drawMovingPiece(ctx, moveDirective, move, paths, pieceWidth);
            } finally {
                ctx.restore();
            }
        }

        // Draw the potential move that could be made.
        if (previewMove !== null) {
            if (previewMove.isIntroduction()) {
                const startFade = (
                    previewMove.getPlayer() === PlayerType.LIGHT
                        ? this.lightStartFade : this.darkStartFade
                );
                startFade.fadeIn(1);
            }
            ctx.save();
            try {
                this.drawPreviewMove(
                    ctx, previewMove, previewIsValidMove,
                    previewReactsToMouse, paths,
                    shape, width, height, pieceWidth,
                );
            } finally {
                ctx.restore();
            }
        }

        // If we are drawing a move, we want to
        // fade out the path around the board.
        if (this.isPathActive) {
            this.pathAlphaFade.fadeInOrOut(move === null && previewMove === null);
        }

        // Draw the path around the board.
        const pathAlpha = this.pathAlphaFade.get();
        if (pathAlpha > 0) {
            ctx.save();
            try {
                ctx.globalAlpha = pathAlpha;
                const fakeMove = new Move(
                    activePlayer, null, null, null, null, null,
                );
                const curve = this.computePathCurve(fakeMove, paths);
                this.drawPath(
                    ctx, fakeMove, true, false, true, paths,
                    curve, pieceWidth, null, null, null,
                );
            } finally {
                ctx.restore();
            }
        }
    }

    computePathCurve(move: Move, paths: PathPair) {
        const path = paths.getWithStartEnd(move.getPlayer());

        let startIndex: number;
        if (move.isIntroduction()) {
            startIndex = 0;
        } else {
            startIndex = move.getSourcePiece().getPathIndex() + 1;
        }

        let endIndex: number;
        if (move.isScore()) {
            endIndex = path.length - 1;
        } else {
            endIndex = move.getDestPiece().getPathIndex() + 1;
        }

        const leftPlayer = this.getPlayers().getLeftPlayer();
        return this.curveCache.getOrCompute(() => {
            const movePath: Vec2[] = [];
            for (let index = startIndex; index <= endIndex; ++index) {
                const bounds = this.boardAsset.getTileBounds(path[index], leftPlayer);
                movePath.push(bounds.getCentre());
            }
            return Vec2.createBezierCurve(movePath, 1);
        }, [startIndex, endIndex, path, this.boardAsset.getBoardBounds()]);
    }

    drawMovingPiece(
        ctx: CanvasRenderingContext2D,
        directive: MoveBoardDirective,
        move: Move,
        paths: PathPair,
        pieceWidth: number,
    ) {
        const scoring = move.isScore();
        const capturing = move.isCapture();

        const time = getTimeSeconds();
        const animTime = time - this.moveStartTime;
        const moveAge = clamp(animTime / this.moveDuration, 0, 1);
        const doneMoving = (animTime >= this.moveDuration);
        const animAge = (
            this.animDuration > 0
                ? clamp((animTime - this.moveDuration) / this.animDuration, 0, 1)
                : 0
        );
        const curve = this.computePathCurve(move, paths);

        if (animTime - BoardRenderer.AUDIO_DELAY > this.moveDuration && !this.playedPlaceSound) {
            this.playedPlaceSound = true;
            if (directive.moveWinsGame) {
                this.playVictorySound();

            } else if (capturing) {
                this.maybeStartMusic();
            }

            if (scoring) {
                this.playScoreSound();
            } else if (capturing) {
                // Wait until the animation progresses more.
                if (animAge < 0.65) {
                    this.playedPlaceSound = false;
                } else {
                    this.playCaptureSound();
                }
            } else if (move.isLandingOnRosette(new StandardBoardShape())) {
                this.playRosetteSound();
            } else {
                this.playPlaceSound();
            }
        }

        if (capturing) {
            const capturedPiece = move.getCapturedPiece();
            const capturedLocation = curve[curve.length - 1];
            const capturedPieceImage = this.theme.getPieceImage(capturedPiece.getOwner(), false);
            drawPiece(ctx, capturedPieceImage, capturedLocation, pieceWidth);
        }

        const curveIndex = clamp(
            (0.3 * easeInOutSine(moveAge) + 0.7 * easeInOutCubic(moveAge)) * curve.length,
            0, curve.length - 1
        );

        const below = curve[Math.floor(curveIndex)];
        const above = curve[Math.ceil(curveIndex)];
        const aboveRatio = curveIndex % 1.0;
        const location = above.mul(aboveRatio).add(below.mul(1.0 - aboveRatio));

        let drawWidth = pieceWidth;
        drawWidth *= (1 + 0.2 * Math.sin(moveAge * Math.PI));
        if (scoring) {
            drawWidth *= (1 + 0.25 * easeInOutSine(animAge));
        }
        if (capturing) {
            drawWidth *= (1 + 0.15 * moveAge);
            drawWidth /= (1 + 0.15 * animAge * animAge * animAge);
        }

        const pieceSelected = ((this.moveGrantsExtraRoll || scoring) && doneMoving);
        const pieceImage = this.theme.getPieceImage(move.getPlayer(), pieceSelected);

        if (scoring) {
            ctx.globalAlpha = easeInOutSine(1 - animAge);
        }
        drawPiece(ctx, pieceImage, location, drawWidth);
        if (scoring) {
            ctx.globalAlpha = 1.0;
        }
    }

    drawPath(
        ctx: CanvasRenderingContext2D,
        move: Move,
        isValidMove: boolean,
        reactsToMouse: boolean,
        isFullPath: boolean,
        paths: PathPair,
        curve: Vec2[],
        pieceWidth: number,
        hoveredTile: Tile | null,
        draggedTile: Tile | null,
        dragLoc: Vec2 | null,
    ) {
        // Simplifying the line helps avoid paths with
        // many points, which Safari struggles with.
        curve = this.renderCurveCache.getOrCompute(() => {
            return Vec2.simplifyLine(curve, 1);
        }, [curve]);

        const time = getTimeSeconds();

        ctx.save();
        ctx.beginPath();

        const startTile = move.getSource(paths);
        const endTile = move.getDest(paths);
        const dragging = Tile.areEqual(startTile, draggedTile);

        if (isFullPath) {
            ctx.setLineDash([pieceWidth / 3, pieceWidth / 3]);
        } else {
            ctx.setLineDash([pieceWidth / 6, pieceWidth / 3]);
        }

        // Make the dashes move over time
        let dashOffset = 0.75 * pieceWidth * time;
        if (reactsToMouse) {
            const mouseState = this.mouseListener.getMouseState();
            if (mouseState.mouseDown) {
                // Make the dashes move more slowly when dragging the tile
                const mouseDownTime = mouseState.mouseDownTime;
                const mouseDownDuration = (mouseDownTime !== null ? mouseDownTime - time : 0);
                dashOffset += 0.3 * pieceWidth * mouseDownDuration;
            }
        }
        ctx.lineDashOffset = dashOffset;

        const endLoc = curve[curve.length - 1];
        ctx.moveTo(endLoc.x, endLoc.y);

        // If dragging the piece, draw a straight line to it.
        let drawPath = true;
        if (dragging && dragLoc !== null) {
            const dragDistFromStart = dragLoc.sub(curve[0]).lengthSq();
            const drawLineThreshold = BoardRenderer.DRAG_DRAW_LINE_THRESHOLD * pieceWidth;
            if (dragDistFromStart > drawLineThreshold * drawLineThreshold) {
                drawPath = false;
                ctx.lineTo(dragLoc.x, dragLoc.y);
            }
        }

        // If the piece is on its original tile, draw the path it will take.
        if (drawPath) {
            for (let index = curve.length - 2; index >= 0; --index) {
                const point = curve[index];
                ctx.lineTo(point.x, point.y);
            }
        }

        if (isValidMove) {
            const isEndHovered = Tile.areEqual(hoveredTile, endTile);
            ctx.strokeStyle = (isEndHovered ? rgb(100, 255, 100) : (isFullPath ? rgb(230) : rgb(255)));
        } else {
            ctx.strokeStyle = rgb(255, 70, 70);
        }

        ctx.shadowColor = "#000000";
        ctx.shadowBlur = pieceWidth / 10 * (isFullPath ? 2 : 1);
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.lineWidth = pieceWidth / 6;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.stroke();
        ctx.closePath();

        const drawPathEnd = (locIndex: number, type: "cross" | "arrow") => {
            const index1 = (locIndex < 0 ? curve.length + locIndex : locIndex);
            const index2 = (locIndex < 0 ? index1 - 1 : index1 + 1);
            const anchorLoc = curve[index1];
            let dir = anchorLoc.sub(curve[index2]).toUnitLength();
            if (dir.lengthSq() === 0) {
                dir = new Vec2(0, -1);
            }

            ctx.beginPath();
            ctx.setLineDash([]);

            const moveTo = (vec: Vec2) => ctx.moveTo(vec.x, vec.y);
            const lineTo = (vec: Vec2) => ctx.lineTo(vec.x, vec.y);

            if (type === "cross") {
                const crossSize = pieceWidth / 6;
                const crossVec = dir.rotate(Math.PI / 4).toLength(crossSize);
                moveTo(anchorLoc.add(crossVec));
                lineTo(anchorLoc.add(crossVec.rotate(Math.PI)));
                moveTo(anchorLoc.add(crossVec.rotate(-Math.PI / 2)));
                lineTo(anchorLoc.add(crossVec.rotate(-3 * Math.PI / 2)));
            } else {
                const arrowSize = pieceWidth / 4;
                const arrowVec = dir.rotate(Math.PI / 5).toLength(arrowSize);
                moveTo(anchorLoc.sub(arrowVec));
                lineTo(anchorLoc);
                moveTo(anchorLoc.sub(arrowVec.rotate(-2 * Math.PI / 5)));
                lineTo(anchorLoc);
            }

            ctx.stroke();
            ctx.closePath();
        };

        // Draw a cross if it's not a valid move
        ctx.shadowBlur = pieceWidth / 20 * (isFullPath ? 2 : 1);
        ctx.lineWidth = pieceWidth / 5.5;
        if (!isValidMove) {
            drawPathEnd(-1, "cross");
        } else if (isFullPath) {
            ctx.strokeStyle = rgb(110, 110, 255);
            drawPathEnd(0, "cross");
            ctx.strokeStyle = rgb(90, 240, 90);
            drawPathEnd(-1, "arrow");
        }
        ctx.restore();
    }

    drawPreviewMove(
        ctx: CanvasRenderingContext2D,
        move: Move,
        isValidMove: boolean,
        reactToMouse: boolean,
        paths: PathPair,
        shape: BoardShape,
        width: number,
        height: number,
        pieceWidth: number,
    ) {
        const leftPlayer = this.getPlayers().getLeftPlayer();

        // Gather data about the mouse and dragging pieces.
        let draggedTile: Tile | null = null;
        let hoveredTile: Tile | null = null;
        let dragOffset: Vec2 | null = null;

        if (reactToMouse) {
            const mouseState = this.mouseListener.getMouseState();
            const mouseLoc: Vec2 | null = mouseState.location;
            const mouseDownLoc: Vec2 | null = (
                isValidMove && mouseState.mouseDown ? mouseState.mouseDownLocation : null
            );

            if (mouseLoc !== null) {
                hoveredTile = this.boardAsset.getTileAt(mouseLoc, leftPlayer);

                if (mouseDownLoc !== null) {
                    draggedTile = this.boardAsset.getTileAt(mouseDownLoc, leftPlayer);
                    dragOffset = mouseLoc.sub(mouseDownLoc);
                }
            }
        }

        // Convert the tile path to a canvas location curve
        const curve = this.computePathCurve(move, paths);
        let dragLoc = (dragOffset !== null ? curve[0].add(dragOffset) : null);
        if (dragLoc !== null) {
            dragLoc = new Vec2(
                clamp(dragLoc.x, pieceWidth, width - pieceWidth),
                clamp(dragLoc.y, pieceWidth, height - pieceWidth),
            );
        }

        let drawPieceWidth = pieceWidth * BoardRenderer.PIECE_HOVERED_WIDTH;
        if (isValidMove) {
            drawPieceWidth *= 1 + 0.03 * Math.cos(5 * getTimeSeconds());
        }

        if (this.getPreferences().showMoveGuidelines) {
            // Draw the path!
            this.drawPath(
                ctx, move, isValidMove, reactToMouse,
                false, paths, curve, pieceWidth,
                hoveredTile, draggedTile, dragLoc,
            );

            // Get the type of piece to draw at the destination.
            let endPieceOwner: PlayerType | null = null;
            if (move.isCapture()) {
                endPieceOwner = move.getCapturedPiece().getOwner();
            } else if (isValidMove) {
                endPieceOwner = move.getPlayer();
            }

            // Draw the destination piece!
            if (endPieceOwner !== null) {
                const moveDest = move.getDest(paths);
                const pieceHovered = !!(hoveredTile?.equals(moveDest));
                const pieceImage = this.theme.getPieceImage(endPieceOwner, pieceHovered);
                const bounds = this.boardAsset.getTileBounds(moveDest, leftPlayer);
                drawPiece(ctx, pieceImage, bounds.getCentre(), drawPieceWidth);
            }
        }

        // Draw the source tile.
        const moveSource = move.getSource(paths);
        if (dragLoc === null) {
            const sourceSelected = !!this.selectedTile?.equals(moveSource);
            const sourceHovered = !!hoveredTile?.equals(moveSource);

            const pieceHighlighted = (sourceSelected || sourceHovered);
            const pieceImage = this.theme.getPieceImage(move.getPlayer(), pieceHighlighted);
            const bounds = this.boardAsset.getTileBounds(moveSource, leftPlayer);
            drawPiece(ctx, pieceImage, bounds.getCentre(), drawPieceWidth);
        } else {
            const draggedOverTile = this.boardAsset.getTileAt(dragLoc, leftPlayer);
            const draggedOverBoard = (draggedOverTile !== null && shape.contains(draggedOverTile));

            drawPieceWidth = pieceWidth;
            if (draggedOverBoard) {
                drawPieceWidth *= BoardRenderer.PIECE_HOVERED_WIDTH;
            }

            const pieceImage = this.theme.getPieceImage(move.getPlayer(), true);
            drawPiece(ctx, pieceImage, dragLoc, drawPieceWidth);
        }
    }
}

function useBoardRenderer(
    players: GamePlayers,
    listener: MouseListener,
    isPathActive: boolean,
    sharedRendererState: SharedRendererState,
): BoardRenderer {
    const gameTheme = useGameTheme();
    const user = useOptionalUser();
    const assetLoader = useAssetLoader();
    const { preferences } = useGamePreferences(user);
    const boardController = useBoardController();

    const renderer = useMemo(
        () => new BoardRenderer(
            gameTheme, boardController, listener,
            sharedRendererState, assetLoader,
        ),
        [
            gameTheme, boardController, listener,
            sharedRendererState, assetLoader,
        ],
    );

    // Link these outside of the constructor to avoid
    // re-creating the renderer unnecessarily.
    renderer.setPlayers(players);
    renderer.setPreferences(preferences);
    renderer.setPathActive(isPathActive);
    return renderer;
}

interface BoardUIProps {
    isMobile: boolean;
    isPathActive: boolean;
    sharedRendererState: SharedRendererState;
    horizontal?: boolean;
    vertical?: boolean;
}

export default function BoardUI({
    isMobile, isPathActive, sharedRendererState, horizontal, vertical,
}: BoardUIProps) {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const listener = useMouseListener(canvasRef);
    const gameController = useGameController();
    const game = useRune(gameController.getGame());
    const players = useRune(gameController.getPlayers());
    const renderer = useBoardRenderer(
        players, listener, isPathActive, sharedRendererState,
    );
    const theme = useGameTheme();

    let boardBg = null;
    const boardBgData = theme.getBehindBoardBgData();
    if (boardBgData !== null) {
        boardBg = (
            <Image
                className={styles.behind_board_image}
                src={boardBgData}
                alt="Background behind the board" />
        );
    }

    const boardDirective = useBoardDirective();
    const boardBoundsOptional = useRune(renderer.getBoardAsset().boardBounds);

    useEffect(() => {
        return gameController.registerBoardDirectiveSink();
    }, [gameController]);

    const moveHighlightElems = [];
    if (boardBoundsOptional.isPresent() && boardDirective instanceof AnalysisBoardDirective) {
        const boardBounds = boardBoundsOptional.get();
        const paths = game.getRules().getPaths();
        const ratio = window.devicePixelRatio ?? 1.0;

        const moveHighlights = boardDirective.moveHighlights;
        for (let index = 0; index < moveHighlights.length; ++index) {
            const moveHighlight = moveHighlights[index];
            const bounds = renderer.getBoardAsset().calculateTileBounds(
                boardBounds, moveHighlight.move.getSource(paths), players.getLeftPlayer(),
            );
            let left = bounds.left;
            let top = bounds.top;
            if (moveHighlight.move.isIntroduction()) {
                left -= 16 * ratio;
                top -= 16 * ratio;
            } else {
                left -= 8 * ratio;
                top -= 8 * ratio;
            }

            moveHighlightElems.push(
                <FadingDiv
                    key={`${index}_${Math.round(left)}_${Math.round(top)}_${moveHighlight.category.id}`}
                    options={{ duration: 0.2 }}
                    className={styles.move_highlight}
                    style={{
                        left: `${left / ratio}px`,
                        top: `${top / ratio}px`,
                    }}>

                    <MoveCategoryIcon
                        moveCategory={moveHighlight.category} />
                </FadingDiv>
            );
        }
    }

    const containerRef = useRef<HTMLDivElement | null>(null);
    const containerBounds = useBounds(containerRef);

    return (
        <div
            ref={containerRef}
            className={cn(
                styles.board_container,
                (vertical ? styles.vertical
                    : (horizontal ? styles.horizontal
                        : (isMobile ? styles.portrait
                            : styles.landscape))),
            )}
            style={!horizontal && !vertical && !isMobile && containerBounds ? {
                minWidth: 0.46 * containerBounds.height,
            } : {}}>

            {boardBg}
            <RenderedCanvas
                canvasRef={canvasRef}
                renderer={renderer}
                updateEveryFrame={true}
                rotateLeft90={horizontal} />

            {moveHighlightElems}
        </div>
    );
}
