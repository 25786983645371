import forcedIcon from "@/res/icons/analysis/forced.svg";
import brilliantIcon from "@/res/icons/analysis/brilliant.svg";
import greatIcon from "@/res/icons/analysis/great.svg";
import bestIcon from "@/res/icons/analysis/best.svg";
import excellentIcon from "@/res/icons/analysis/excellent.svg";
import goodIcon from "@/res/icons/analysis/good.svg";
import mistakeIcon from "@/res/icons/analysis/mistake.svg";
import blunderIcon from "@/res/icons/analysis/blunder.svg";
import { MoveCategory } from "@/ts/business/analysis/MoveCategory";


export function getMoveCategoryIcon(moveCategory: MoveCategory) {
    if (moveCategory === MoveCategory.WIN)
        return bestIcon;
    if (moveCategory === MoveCategory.FORCED)
        return forcedIcon;
    if (moveCategory === MoveCategory.BRILLIANT)
        return brilliantIcon;
    if (moveCategory === MoveCategory.GREAT)
        return greatIcon;
    if (moveCategory === MoveCategory.BEST)
        return bestIcon;
    if (moveCategory === MoveCategory.EXCELLENT)
        return excellentIcon;
    if (moveCategory === MoveCategory.GOOD)
        return goodIcon;
    if (moveCategory === MoveCategory.MISTAKE)
        return mistakeIcon;
    if (moveCategory === MoveCategory.BLUNDER)
        return blunderIcon;

    throw new Error(`Unknown move category ${moveCategory.id}`);
}
